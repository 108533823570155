import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    {
        path: '/charts/apex',
        name: 'Apex chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/apex')
    },
    {
        path: '/charts/chartjs',
        name: 'Chartjs chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/chartist',
        name: 'Chartist chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartist')
    },
    {
        path: '/charts/echart',
        name: 'Echart chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/echart/index')
    },
    {
        path: '/admin/create-admin',
        name: 'Create Admin',
        meta: { authRequired: false },
        component: () => import('../views/pages/admin/createAdmin')
    },
    {
        path: '/admin/view-admin',
        name: 'View Admin',
        meta: { authRequired: false },
        component: () => import('../views/pages/admin/viewAdmin')
    },
    
    {
        path: '/admin/edit-admin/:id',
        name: 'Edit Admin',
        meta:{authRequired: false},
        component: () => import('../views/pages/admin/editAdmin')
    },
    {
        path: '/user/register',
        name: 'Register user',
        meta: { authRequired: false },
        component: () => import('../views/pages/user/register')
    },
    {
        path: '/admin/register',
        name: 'Register admin',
        meta: { authRequired: false },
        component: () => import('../views/pages/admin/createAdmin')
    },
    {
        path: '/admin/:id',
        name: 'Admin Details',
        meta:{authRequired: false},
        component: () => import('../views/pages/admin/singleAdmin')
    },
    {
        path: '/admins',
        name: 'View admins',
        meta: { authRequired: false },
        component: () => import('../views/pages/admin/viewAdmin')
    },
    {
        path: '/users',
        name: 'View users',
        meta: { authRequired: false },
        component: () => import('../views/pages/user/viewUsers')
    },
    {
        path: '/edit-user/:id',
        name: 'Edit user',
        meta: { authRequired: false },
        component: () => import('../views/pages/user/editUser')
    },
    {
        path:'/site/create-site',
        name:'Create Site',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/createSite')
    },
    {
        path:'/sites',
        name:'View All Sites',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/viewSite')
    },
    {
        path:'/sites/eid',
        name:'View EID',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/eid')
    },
    {
        path:'/sites/vl',
        name:'View VLIS',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/vlis')
    },
    {
        path: '/pt-round/review',
        name: 'review',
        component: () => import('../views/pages/ptround/review'),
        meta:{authRequired: false},
    },
    {
        path: '/site/eid/:id',
        name: 'EID',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/singleEID')

    },
    {
        path: '/site/vl/:id',
        name: 'VLIS',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/singleVLIS')

    },
    {
        path: '/site/eid/edit-site/:id',
        name: 'EID',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/editEID')

    },
    {
        path: '/site/vlis-details',
        name: 'VLIS',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/singleVLIS')

    },
    {
        path: '/site/vl/edit-site/:id',
        name: 'VLIS',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/edit-VLIS')

    },

    {
        path: '/site/view-site',
        name: 'View Sites',
        meta:{authRequired: false},
        component: () => import('../views/pages/site/viewSite')
    },
    {
        path: '/ptround/create-eid-ptround',
        name: 'Create EID PT Round',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/createPTRound')
    },
    {
        path: '/ptround/create-vl-ptround',
        name: 'Create VL PT Round',
        meta:{authRequired: true},
        component: () => import('../views/pages/ptround/createVLPTRound')
    },
    {
        path: '/ptround/eid-ptrounds',
        name: 'View EID PT Round',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/viewPTRound')
    },
    {
        path: '/ptround/vl-ptrounds',
        name: 'View VL PT Round',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/viewVLPTRound')
    },
    {
        path: '/pt-round/:id',
        name: 'View PT Round',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/singlePT')
    },
    {
        path: '/pt-round/edit/:id',
        name: 'Edit EID PT Round',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/editPT')
    },
    {
        path: '/pt-round/tracking-info/:id/',
        name: 'Tracking Info',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/trackingInfo')
    },
    {
        path: '/pt-round/certification/:id',
        name: 'Certification',
        meta:{authRequired: false},
        component: () => import('../views/pages/ptround/certification')
    },
    {
        path: '/biker/create-biker',
        name: 'Create a Transport System',
        meta:{authRequired: false},
        component: () => import('../views/pages/biker/createBiker')
    },
    {
        path: '/biker/bikers',
        name: 'View Transports Systems',
        meta:{authRequired: false},
        component: () => import('../views/pages/biker/bikers')
    },
    {
        path: '/biker/edit-biker/:id',
        name: 'Edit Transports Systems',
        meta:{authRequired: false},
        component: () => import('../views/pages/biker/editBiker')
    },
    {
        path: '/biker/view-biker/:id',
        name: 'Edit Transport System',
        meta:{authRequired: false},
        component: () => import('../views/pages/biker/viewBiker')
    },
    {
        path: '/training/create-training',
        name: 'Create a training',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainings/createTraining')
    },
    {
        path: '/training/create-trainee',
        name: 'Create a trainee',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainee/create-trainee')
    },
    {
        path: '/training/view-trainees',
        name: 'View trainees',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainee/viewTrainees')
    },
    {
        path: '/training/edit-trainee',
        name: 'View trainees',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainee/edit-trainee')
    }
    ,{
        path: '/training/trainee-details/:id',
        name: 'trainee Details',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainee/trainee-details')
    },
    {
        path: '/training/view-trainings',
        name: 'View Trainings',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainings/viewTrainings')
    },
    {
        path: '/training/edit-training',
        name: 'View Trainings',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/trainings/edit-training')
    },
    {
        path: '/training/refresher-trainee',
        name: 'Refresher Trainee ',
        meta:{authRequired: true},
        component: () => import('../views/pages/training/trainee/refresher-trainee')
    },
    {
        path: '/training/certfication/:id',
        name: 'Certification',
        meta:{authRequired: true},
        component: () => import('../views/pages/training/certification/certification')
    },
    {
        path: '/countries',
        name: 'View supported countries',
        meta:{authRequired: false},
        component: () => import('../views/pages/country/countries')
    },
    {
        path: '/regions',
        name: 'View supported regions',
        meta:{authRequired: false},
        component: () => import('../views/pages/regions/region')
    },
    {
        path: '/certification/add-certification-results',
        name: 'Add certification results',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/certification/createCertification')
    },
    {
        path: '/certification/registration',
        name: 'Registration',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/certification/registration')
    },
    {
        path: '/certification/view-successful',
        name: 'View Successful certifications',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/certification/successful-certification')
    },
    {
        path: '/certification/view-failed',
        name: 'View Failed certifications',
        meta:{authRequired: false},
        component: () => import('../views/pages/training/certification/failed-certification')
    }

]
