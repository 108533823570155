<template>
  <div id="app">
    <router-view />
<!--    <Toast></Toast>-->
  </div>
</template>

<script>
import appConfig from "@/app.config";
import {getUser} from "./helpers/helper";
import axios from "axios";
export default {
  name: "app",
  components:{
    // "Toast":toast
  },
  mounted() {
    if(localStorage.getItem("token")){
      if (window.location.pathname==="/login"){
        window.location.href="/";
        return;
      }
      axios.defaults.headers.common['Authorization']='Bearer '+localStorage.getItem('token');
      window.currentUser = getUser();
    }else{
      if (window.location.pathname!=="/login"){
        window.location.href="/login";
        // return;
      }
    }
    window.makeToast=this.makeToast;
  },
  methods:{
    makeToast(title,message,variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      })
    }
  },


  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>