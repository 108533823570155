import axios from "axios";
//export const BASEURL="http://192.168.100.6:5000";

export const download_url = "https://ilis.ghsscm.org/general-upload";  //lien pour téléverser les fichiers
export const url_notification = "https://ilis.ghsscm.org";

export const BASEURL="https://ilis.ghsscm.org";
//export const BASEURL="http://192.168.100.2:5000";

//export const BASEURL="https://e18c-154-72-162-26.ngrok-free.app";


export function check(e){
    console.log(e)
    if(e!=null){
        return 0
    }else{
        return 1
    }
}


export function axios_get(endpoint,data={},success=()=>{},error=()=>{},final=()=>{}) {

    axios.get(BASEURL+endpoint,{params:data}).then(data=>{
        success(data.data)
    }).catch(e=>{
        console.error(e);
        manageError(e);
       // toast("erreur de connexion");
        error(e);
    }).finally(()=>{final()});

}
export function axios_post(endpoint,data={},success=()=>{},error=()=>{},final=()=>{}) {
    axios.post(BASEURL+endpoint,data).then(data=>{
        success(data.data)
        successToast()
    }).catch(e=>{
        console.error(e);
        error(e);
        manageError(e);

    }).finally(()=>{final()});
}
export function axios_put(endpoint,data={},success=()=>{},error=()=>{},final=()=>{}) {
    axios.put(BASEURL+endpoint,data).then(data=>{
        success(data.data)
        successToast()
    }).catch(e=>{
        console.error(e);
        error(e);
        manageError(e);

    }).finally(()=>{final()});
}
export function axios_delete(endpoint,data={},success=()=>{},error=()=>{},final=()=>{}) {

    axios.delete(BASEURL+endpoint,{params:data}).then(data=>{
        success(data.data)
    }).catch(e=>{
        console.error(e);
        manageError(e);
        error(e);
    }).finally(()=>{final()});

}
export function storeUser(user){
    window.localStorage.setItem("user",JSON.stringify(user));
}
export function getUser($attr=null){
    const u = window.localStorage.getItem("user");
    if(!u){
        return null;
    }else{
        if($attr){
            return JSON.parse(u)[$attr];
        }else{
            return JSON.parse(u);
        }
    }
}
export function manageError(e){
    if(e.response.data && e.response.data.msg){
        window.makeToast('Error',e.response.data.msg,'danger');
    }else{
        window.makeToast('Error','Please check your network access and try again!','danger');
    }
}

export function successToast(){
    window.makeToast('Success','Successfully create or update','success');
}
